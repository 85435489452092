import { clearUser as clearUserAction } from "../auth"
import { login } from "../auth/actions/login/login.action"
import { User } from "../auth/dto/user.object"
import { UserAttributes } from "../internal-api/auth0"
import { useAppDispatch, useAppSelector } from "../store/hooks"

const useUser = (): {
  user: User | null
  saveUser: (value: string) => void
  clearUser: () => void
} => {
  const user = useAppSelector((state) => state.auth.data.user)
  const dispatch = useAppDispatch()

  const saveUser = (value: string) => {
    const parsedValue = JSON.parse(value) as UserAttributes

    if (parsedValue.user_id) {
      dispatch(login(parsedValue))
    }
  }

  const clearUser = () => {
    dispatch(clearUserAction)
  }

  return { user, saveUser, clearUser }
}

export default useUser
