import { ActionTypes } from "../reducers/request-handling.reducer"
import {
  RequestState,
  Block,
  ErrorBlock,
} from "../context/request-handling.context"

export const requestStarted = () => ({
  type: ActionTypes.REQUEST_STARTED,
})

export const requestSucceeded = (payload: Partial<RequestState>) => ({
  type: ActionTypes.REQUEST_SUCCEEDED,
  payload,
})

export const requestFailed = (payload: Partial<RequestState>) => ({
  type: ActionTypes.REQUEST_FAILED,
  payload,
})

export const setNotification = (payload: Partial<RequestState>) => ({
  type: ActionTypes.SET_NOTIFICATION,
  payload,
})

export const clearNotification = () => ({
  type: ActionTypes.CLEAR_NOTIFICATION,
})

export const executeRequest =
  (execBlock: Block, errorCallback?: ErrorBlock) => async (dispatch: any) => {
    try {
      dispatch(requestStarted)

      const result = await execBlock()

      dispatch(requestSucceeded)

      return result
    } catch (error: any) {
      const { status, title, url, message, errorBody } = error
      dispatch(requestFailed({ status, message }))

      if (errorCallback) {
        errorCallback(message)
      }

      if (window.Rollbar) {
        window.Rollbar.error(`ERROR ${error.message}`, {
          title,
          status,
          message,
          errorBody,
          url,
        })
      }

      return error.body
    }
  }
