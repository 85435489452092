// REFACTOR should be moved to authentication module
import jwt from "jsonwebtoken"
import { navigate } from "@reach/router"
import { useEffect, useState } from "react"
import { executeRequest } from "../actions/request-handling.action"
import { NETLIFY_URL } from "../internal-api/commerce/constants"
import {
  AuthenticateUserQueryParams,
  AuthAttributes,
} from "../auth/auth.interface"
import { getUserData } from "../auth/auth0"
import { Path } from "../navigation/constants/routes"
import { useRequestContext } from "../context/request-handling.context"
import { setNotification } from "../actions/request-handling.action"
import useUser from "./use-user"
import { useNetwork, Method } from "../internal-api/network/use-network.hook"

const config = {
  baseURL: NETLIFY_URL,
}

const isClient = () => typeof window !== "undefined"

export const authenticateUser = async ({
  response,
}: AuthenticateUserQueryParams): Promise<{
  user?: any
  token?: string
  error?: string
}> => {
  const { id_token, access_token } = response.data?.message || {
    id_token: undefined,
    access_token: undefined,
  }

  if (!id_token || !access_token) {
    return { error: `Authentication failed.` }
  }

  const tokenClaims = jwt.decode(id_token)
  const userId = tokenClaims && tokenClaims.sub
  const user = await getUserData(userId, access_token)

  if (user) {
    return {
      user: JSON.stringify(user),
      token: id_token,
    }
  } else {
    return { error: `Authentication failed: could not fetch user's data` }
  }
}

export const useAuthentication = () => {
  const { dispatch } = useRequestContext()
  const { saveUser } = useUser()
  const [authorizationPending, setAuthorizationPending] = useState(true)
  const [response, fetch] = useNetwork<AuthAttributes, any>(
    { url: "/authorize-user", method: Method.Post },
    config
  )

  const confirmationRegExp = /success#|error#/

  const queryParams = isClient() && new URLSearchParams(window.location.search)
  const code = queryParams && queryParams.get("code")
  const success = queryParams && queryParams.get("success")
  const message = queryParams && queryParams.get("message")

  useEffect(() => {
    if (code && window.location.href.length) {
      if (confirmationRegExp.test(code) && success) {
        dispatch(
          setNotification({
            type: success === "true" ? "success" : "error",
            message: message as string,
          })
        )

        navigate(Path.Home)

        return
      }

      dispatch(
        executeRequest(async () => {
          const data = {
            code,
            redirect: window.location.origin + Path.Redirect,
          }
          await fetch({ data })
        }),
        (error: string) => {
          window.Rollbar.error(`Authentication failed ${error}`)
          window.alert("Authentication failed. Try again later.")
        }
      )
    } else {
      setAuthorizationPending(false)
    }
  }, [])

  useEffect(() => {
    dispatch(
      executeRequest(async () => {
        if (!response.data) {
          return
        }

        const { user, token } = await authenticateUser({
          response,
        })

        if (user && token) {
          saveUser(user)
        }

        setAuthorizationPending(false)
      })
    )
  }, [response.data])

  useEffect(() => {
    if (response.error?.response) {
      dispatch(
        setNotification({
          type: "error",
          message:
            "It appears, we are experiencing unusual technical issues with logging you in. We’ve got down to fix it but you can still try to log in again.",
        })
      )
      const errorTimeout = setTimeout(() => {
        setAuthorizationPending(false)
      }, 4500)

      return () => {
        clearTimeout(errorTimeout)
      }
    }

    return () => null
  }, [response.error])

  return authorizationPending
}
